import { handleQueryResolve } from '../utils'

export default function () {
  const attributes = []

  // if(name){
  //     attributes.push({
  //         param: 'name',
  //         type: 'sql.VarChar(50)',
  //         value: '%' + name + '%'
  //     })
  // }
  return this.query(`
    SELECT
        Patient.Id AS PatientID,
        Patient.LastName AS LastName,
        Patient.FirstName AS FirstName,
        SUBSTRING(A.Phone,1,3)+'-'+SUBSTRING(A.Phone,4,3)+'-'+SUBSTRING(A.Phone,7,4) AS PhoneNumber
    FROM
        Patient
    JOIN Prescription on Patient.ID = Prescription.PatientId
    JOIN Drug ON Prescription.DrugId = Drug.Id
    JOIN AddressRole AR on Patient.Id = AR.EntityId
    JOIN Address A on AR.AddressId = A.ID
    WHERE
        FillDate BETWEEN DATEADD(month,-3,GetDate()) And GetDate()
    AND AR.EntityCode = 'PAT'
    AND
        Patient.ID NOT IN (
            SELECT DISTINCT
                PatientId
            FROM Prescription
            JOIN Drug ON Prescription.DrugId = Drug.Id
            WHERE Filldate BETWEEN DATEADD(year,-1,GetDate()) And GetDate()
            AND DIN IN (93899979, 93899988, 93899987)
        )
    GROUP BY
        Patient.Id,
        LastName,
        FirstName,
        A.Phone
    HAVING count(DIN) > 2
    ORDER BY LastName, FirstName
    `).then(handleQueryResolve)
}
